<template>
  <div ref="rootNode">
    <div />
  </div>
</template>

<script>

import * as THREE from 'three';
import { emits, inject, onMounted, onUnmounted, ref, toRaw, watch } from 'vue';
import Camera from './Camera.vue';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import { OBJExporter } from 'three/addons/exporters/OBJExporter.js';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { decode } from 'fast-png';
import * as landscapeShader from './LandscapeShader.js'

export default {
    name: 'Terrain',
        "components": {
        },
        "props": {
            params: {
                type: Object,
                required: true,
            },
            surfaceMesh: {
                type: Object,
                required: true
            }
        },
        "emits": [],
        setup(props, { emit }) {

            const scene = inject('scene'),
            axios = inject('Axios'),
            camera = inject('camera'),
            keyPress = inject('keyPress'),
            renderer = inject('renderer'),
            rootNode = ref(null),
            textureMaps = [],
            buffers = {},
            scale = ref(1),
            worker = new Worker(new URL('./terrain-worker.js', import.meta.url)),
            textureLoader = new THREE.TextureLoader(),
            trackPosition = new THREE.Vector3(),
            terrainNode = ref(new THREE.Group()),
            terrainData = ref({}),
            terrainMaterial = ref({}),
            currentGrid = ref([7,12]), // which grid the camera is in
            cacheMap = ref({}),
            debounceKey = ref({}),
            mapSize = ref(3),
            activeLights = {},
            heightMaps = ref({}),
            decimateMaps = ref({}),
            normalMaps = ref({}),
            geometryQueue = {},
            mergePairs = {}, // pre-calculated lod neighbor segment vertex pairs for merging normals
            inUpdate = ref(false),
            updateDelta = ref(125), // how far we the camera move without updating
            loader = new THREE.ObjectLoader(),
            /*
             * Overview ! So how is this map architected ?
             * We have segments in json that contain the data of the map, here is a segment sample
             *   {
             *     "terrain": {
             *       "scale": 1,
             *       "lods": [256, 128, 64, 32, 16, 8],
             *       "position": [0,0,0],
             *       "rotation": [90,0,0],
             *       "grids": [{
             *         "id": [-3,3],
             *         "position": [0,0,0],
             *         "rotation": [0,0,0],
             *         "alphablack": false,
             *         "heightmap": "public/terrain/tile-00001.png"
             *       },
             *
             * So each grid will have data on a high level with a grid x,y id, think GPS grid reference
             * The map then behaves as a window that runs over these grids and can span multiple grids
             * The number of grids the map loads is managed by 'mapSize' and 'currentGrid' tracks the
             * grid that the camera is presently in.
             *
             * Within a map then there are multiple mesh segments, that make
             *
             *
             */
            loadTerrain = () => {

                axios.get(
                  //`${process.env.VUE_APP_SERVER_URI}public/terrain.json`,
                  `https://www.intradeep.com/public/terrain.json`,
                  {

                      "headers": {
                      }

                  }

                ).
                  then(async (response) => {

                      if (response.status === 200) {

                          const n = response.data;
                          terrainData.value = n.terrain;

                          calculateMergePairs();
                          await loadTextures();
                          console.log('textureMaps')
                          console.log(textureMaps)
                          loadMaps();

                      }

                }).
                catch((error) => {console.log(error)});

            },
            loadMaps = async () => {

                getTerrainMaterial();

                // load the height and normal data in worker  
                for (var i=0; i< terrainData.value.grids.length; i++) {

                    const gridX = terrainData.value.grids[i].id[0];
                    const gridY = terrainData.value.grids[i].id[1];
                    const z = terrainData.value.heightScale > 0 ? terrainData.value.heightScale : 1.0;

                    let {width,height,data} = await loadHeightMap(terrainData.value.grids[i].heightmap);

                    const scale = {
                      //x: terrainData.value.scale,
                      //y: terrainData.value.scale,
                      //z: heightScale.value
                      x: 1,
                      y: 1,
                      z
                    }

                    worker.postMessage({
                        // action: 'getHeightNormalMap', // enable this to load normals from image (high mem usage)
                        action: 'getHeightMap',
                        request: {
                          width,
                          height,
                          data,
                          scale,
                          gridX,
                          gridY
                        }
                    });

                }

            },
            buildMap = async () => {

                const width = terrainData.value.lods[0] * terrainData.value.scale * terrainData.value.grids.length,
                  height = terrainData.value.lods[0] * terrainData.value.scale * terrainData.value.grids.length,
                  spg = terrainData.value.gridSize / terrainData.value.lods[0];

                scale.value = terrainData.value.scale;

                let x, y, z;

                // DEBUG I'm usingg 5 for i and j, just to test load from middle of map
                for (let i=0; i < mapSize.value * spg; i++) {

                    x = (i * terrainData.value.lods[0] - terrainData.value.lods[0]/2) * terrainData.value.scale;

                    for (let j=0; j < mapSize.value * spg; j++) {

                        y = (j * terrainData.value.lods[0] - terrainData.value.lods[0]/2) * terrainData.value.scale;

                        const terrainInfo = await getTerrainInfo(i, j),
                          // material = terrainMaterial.value,
                          material = getTerrainMaterial(i, j),
                          geometry = new THREE.BufferGeometry(),
                          segment = new THREE.Mesh( geometry, material );
                          segment.castShadow = true;
                          segment.receiveShadow = true;

                        // Add neighbor pointers (utilized for stitching edges)
                        if (j > 0) {

                            const prev = terrainNode.value.children[terrainNode.value.children.length - 1];
                            if (prev) {
                                terrainInfo.neighbors.north = prev;
                                prev.userData.terrainInfo.neighbors.south = segment;
                            }

                        }
                        if (i > 0) {

                            const prev = terrainNode.value.children[terrainNode.value.children.length - mapSize.value * spg];
                            if (prev) {
                                terrainInfo.neighbors.west = prev;
                                prev.userData.terrainInfo.neighbors.east = segment;
                            }

                        }

                        segment.userData.terrainInfo = terrainInfo;

                        segment.position.set(x, y, terrainData.value.heightOffset);
                        segment.position.add(new THREE.Vector3(-width/2, -height/2, height/10));

                        terrainNode.value.add(segment);

                    }

                }

                // set terrain position and rotation
                terrainNode.value.position.set(
                  terrainData.value.position[0],
                  terrainData.value.position[1],
                  terrainData.value.position[2]);

                terrainNode.value.rotation.set(
                  THREE.MathUtils.degToRad(terrainData.value.rotation[0]),
                  THREE.MathUtils.degToRad(terrainData.value.rotation[1]),
                  THREE.MathUtils.degToRad(terrainData.value.rotation[2]));

                scene.add(toRaw(terrainNode.value));

                // do an initial update
                let {newTerrain:newTerrain, updateTerrain:updateTerrain} = updateLOD();

                worker.postMessage({
                    action: 'buildTerrainGeometry',
                    request: newTerrain
                });

        },
        loadTextures = async (callback) => {

            const textures = [

                // 0 diffuse
                [
                  "public/textures/meadowgrass_diffuse_1k.jpg",
                  "public/textures/grassyrocks_diffuse_1k.jpg",
                  "public/textures/gravel_diffuse_1k.jpg",
                  "public/textures/moss_diffuse_1k.jpg",
                  "public/textures/rockydirt_diffuse_1k.jpg",
                  "public/textures/boulder_diffuse_1k.jpg"
                ],
                // 1 normal
                [
                  "public/textures/meadowgrass_normal_1k.jpg",
                  "public/textures/grassyrocks_normal_1k.jpg",
                  "public/textures/gravel_normal_1k.jpg",
                  "public/textures/moss_normal_1k.jpg",
                  "public/textures/rockydirt_normal_1k.jpg",
                  "public/textures/boulder_normal_1k.jpg"
                ],
                // 2 roughness
                [
                  "public/textures/meadowgrass_roughness_1k.jpg",
                  "public/textures/grassyrocks_roughness_1k.jpg",
                  "public/textures/gravel_roughness_1k.jpg",
                  "public/textures/moss_roughness_1k.jpg",
                  "public/textures/rockydirt_roughness_1k.jpg",
                  "public/textures/boulder_roughness_1k.jpg"
                ],
                // 3 ambient occlusion
                [
                  "public/textures/meadowgrass_ao_1k.jpg",
                  "public/textures/meadowgrass_ao_1k.jpg",
                  "public/textures/meadowgrass_ao_1k.jpg",
                  "public/textures/meadowgrass_ao_1k.jpg",
                  "public/textures/meadowgrass_ao_1k.jpg",
                  "public/textures/meadowgrass_ao_1k.jpg"
/*
                  "public/textures/meadowgrass_ao_1k.jpg",
                  null,
                  null,
                  "public/textures/moss_ao_1k.jpg",
                  null,
                  null
*/
                ],

            ]

            for (let t = 0; t < textures.length; t++) {

                const dataArr = new Uint8Array(textures[t].length * 4 * 1024 * 1024);

                for (let i = 0; i < textures[t].length; i++) {

                    const {width,height,data} = await loadImage(textures[t][i]);
                    const offset = i * (4 * 1024 * 1024);

                    dataArr.set(data, offset);

                }

                console.log("dataArr")
                console.log(dataArr)
                console.log("textures[t].length")
                console.log(textures[t].length)

                //textureMaps[t] = new THREE.DataTexture2DArray(dataArr, 1024, 1024, textures[t].length);
                textureMaps[t] = new THREE.DataArrayTexture(dataArr, 1024, 1024, textures[t].length);

                if (t === 1) {

                    textureMaps[t].encoding = THREE.LinearEncoding;
                    textureMaps[t].format = THREE.RGBAFormat;
                    textureMaps[t].type = THREE.UnsignedByteType;
                    textureMaps[t].minFilter = THREE.LinearMipMapLinearFilter;
                    textureMaps[t].magFilter = THREE.LinearFilter;
                    textureMaps[t].wrapS = THREE.RepeatWrapping;
                    textureMaps[t].wrapT = THREE.RepeatWrapping;
                    textureMaps[t].repeat.set(0.05, 0.05);
                    textureMaps[t].generateMipmaps = true;
                    textureMaps[t].needsUpdate = true;

                } else {

                    textureMaps[t].encoding = THREE.sRGBEncoding;
                    textureMaps[t].format = THREE.RGBAFormat;
                    textureMaps[t].type = THREE.UnsignedByteType;
                    textureMaps[t].minFilter = THREE.LinearMipMapLinearFilter;
                    textureMaps[t].magFilter = THREE.LinearFilter;
                    textureMaps[t].wrapS = THREE.RepeatWrapping;
                    textureMaps[t].wrapT = THREE.RepeatWrapping;
                    textureMaps[t].repeat.set(0.05, 0.05);
                    textureMaps[t].generateMipmaps = true;
                    textureMaps[t].needsUpdate = true;

                }

            }

        },
        getTerrainMaterial = (x, y) => {

//shadowmap
/*
  shadowMap: { type: "tv", value: 6, texture: [] },
  shadowMapSize: { type: "v2v", value: [] },
  shadowBias: { type: "fv1", value: [] },
  shadowDarkness: { type: "fv1", value: [] },
  shadowMatrix: { type: "m4v", value: [] }
*/
            let shadowMapsLength = 0;
            let sun = scene.getObjectByName('sun');

            activeLights['sun'] = sun;

            const viewDirection = new THREE.Vector3();
            camera.value.camera.getWorldDirection(viewDirection);
            
           // shaderMaterial.glslVersion = THREE.GLSL3;

            const customMaterial = new THREE.MeshStandardMaterial({
              //  color: 0xffffff,
                roughness: 0.75,
                metalness: 0.1,
                //normalMap: normalMaps[],
                //defines: { USE_IRIDESCENCE: true }
            });

            const uniforms = {

                diffuseMaps: { value: textureMaps[0] },
                normalMaps: { value: textureMaps[1] },
                roughnessMaps: { value: textureMaps[2] },
                aoMaps: { value: textureMaps[3] },
                sunPosition: { value: sun.position.clone() },
                viewDirection: { value: viewDirection }

            }

            const custom_ = landscapeShader.build(customMaterial, uniforms);
            terrainMaterial.value = custom_;
            return custom;

        },
        getTerrainInfo = async (x,y) => {

            var lowX, lowY;

            for (var i=0; i< terrainData.value.grids.length; i++) {

                lowX = lowX < terrainData.value.grids[i].id[0] ? lowX : terrainData.value.grids[i].id[0];
                lowY = lowY < terrainData.value.grids[i].id[1] ? lowY : terrainData.value.grids[i].id[1];

            }

            const spg = terrainData.value.gridSize / terrainData.value.lods[0];
            const gridX = Math.floor(x / spg) + lowX;
            const gridY = Math.floor(y / spg) + lowY;

            var i = 0
            for (var j=0; j< terrainData.value.grids.length; j++) {

                if (terrainData.value.grids[i][0] == lowX && terrainData.value.grids[i][1] == lowY) {

                    i = j;

                }

            }

            const texture = textureLoader.load(terrainData.value.grids[i].heightmap);
            texture.minFilter = THREE.NearestFilter;
            texture.colorSpace = THREE.SRGBColorSpace;
        //    texture.generateMipmaps = false;

            // var height = new Float32Array(terrainData.value.lods[0]*terrainData.value.lods[0]);
            var height = [];
            var normal = [];
            var tangent = [];
            var bitangent = [];

            // find the x and y position in the grid heightmap
            var xpos = x%spg;
            var ypos = y%spg;

            let t = 0;
            let L = terrainData.value.lods[0];

            // two edge vertices are shared, so the mesh segments merge automatically
            //var start = ypos * L * (L * spg + 1) + (L * xpos);
            //var end = start + (L * spg + 1) * L + L;
            var start = ypos * L * (L * spg + 1) + (L * xpos);
            var end = start + L * (L * spg + 1) + L;

/*
            if (xpos === spg - 1) {

                end += L * spg + 1;

            }
*/

            // loop over each segment
            // +1 because we supply an image 1 pixel larger than grid size so grid verts match.
            for (var k=start; k <= end; k+=(L * spg + 1)) {

                // we provide one pixel more(+1) so that the segments share a horizontal height.
                // because of this we need to compensate the skew when generating geometry.

                const heightPart = heightMaps.value[`${gridX},${gridY}`]
                  .slice(k, k + L + 1);
                height.push(...heightPart);

/*

                // add precomputed normals 
                const normalPart = normalMaps.value[`${gridX},${gridY}`]
                  .slice(k*3, (k + L)*3 + 3);
                normal.push(...normalPart);

*/

            }

            return {

                coords:`${x  },${  y}`,
                grid:{x:gridX,y:gridY},
                lod:terrainData.value.lods.length - 1,
                lods:terrainData.value.lods,
                scale:terrainData.value.scale,
                heightmap:`${terrainData.value.grids[i].heightmap}`,
                height,
                normal,
                tangent,
                position:terrainData.value.grids[i].position,
                rotation:terrainData.value.grids[i].rotation,
                uvShift:{x:(x%spg)/spg,y:(y%spg)/spg,s:1/spg}, // how much we should adjust the UV
                neighbors: {} // n,e,s,w

            }

        },
        /*
         * This is the main update function that streams new terrain in, this works relatively well but there are issues
         *
         * - loading buffer geometry is kinda slow, but this needs to be done in the main thread, it is a limitation
         *   of web workers and gpu access. Right now we use the web worker to build the terain data and then just generate
         *   the result in the main thread in a somewhat async way that tries not to block much.
         * - potentially we could move pass 1 lod update into the webworker too, it may say 30 odd ms every 3 seconds.
         * - TODO : we need to have some mechanism to save data and edit the mesh, so like some meshes could ahve custom 
         *   data, caves etc, for thos segments we could maybe save / load from threejs object format or use obj loader /
         *   exporter. Seams may be an issue though. Say you have 5 lods and 16 combinations of seams, it's going to be a
         *   problematic, how can we manage seams... maybe generate them separately ?
         *   Here is a possible solution
         *   - each LOD is basically a grid so you can change / store the x,y,z of custom vertices, the indices don't
         *     change, this can allow for overhangs, we just store each custom segment for each lod, the stitchs should
         *     behave the same as the vertex xy counts are the same.
         *   - For caves , caverns of any other more complex customizations, the developer needs to mark the vertices
         *     that are to be removed and supply custom models for each LOD that match, I'm unsure how stitches will
         *     work, but it will probably be problematic, the developer will need to supply models that can work in each
         *     situation, that can be somewhat complext.
         *   - The only other way would be to do away with stiches and find some other solution, meshlets or something.
         */
        updateLOD = () => {

            var startTime = performance.now();

            // first pass : calculate and set LOD for each segment.
            for (let i=0; i < terrainNode.value.children.length; i++) {

                const worldPosition = new THREE.Vector3();
                terrainNode.value.children[i].getWorldPosition(worldPosition);

                const d = worldPosition.distanceTo(camera.value.camera.position),
                //const d = worldPosition.distanceTo(new THREE.Vector3(0,0,0)),
                 ti = terrainNode.value.children[i]?.userData?.terrainInfo;

                const prevLod = ti.lod;
                ti.prevLod = ti.lod;

                // Calculate LOD based on distance
                if (d < ti.lods[0] * ti.scale * 2.0) {

                    ti.lod = 0;

                } else if (d < ti.lods[0] * ti.scale * 3.5) {

                    ti.lod = 1;

                } else if (d < ti.lods[0] * ti.scale * 5.0) {

                    ti.lod = 2;

                } else if (d < ti.lods[0] * ti.scale * 6.5) {

                    ti.lod = 3;

                } else if (d < ti.lods[0] * ti.scale * 8.0) {

                    ti.lod = 4;

                } else {

                    ti.lod = 5;

                }

            }

            var endTime = performance.now()
            console.log(`update pass 1 took ${endTime - startTime} milliseconds`)

            var startTime = performance.now()

            // we batch the request data in here and pass it all to a worker thread at the end

            // segments that have a different lod
            const newTerrain = [];
            // segments that have the same lod but different edge
            const updateTerrain = [];

            // Second pass, render based on LOD
            for (let i=0; i < terrainNode.value.children.length; i++) {

                const ti = terrainNode.value.children[i].userData.terrainInfo,
                  c = terrainNode.value.children,
                  // Calculate edges based on neighbors (n,e,s,w)
                  edgeBounds = [
                    (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.north?.userData?.terrainInfo?.lod),
                    (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.east?.userData?.terrainInfo?.lod),
                    (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.south?.userData?.terrainInfo?.lod),
                    (c[i].userData?.terrainInfo?.lod + 1 == c[i].userData?.terrainInfo?.neighbors?.west?.userData?.terrainInfo?.lod),
                  ];

                ti.edgeBounds = ti.edgeBounds ?? [];

                // only update when necessary
                var newEdge = false;
                for (var j=0; j < edgeBounds.length; j++) {

                    if (edgeBounds[j] != ti.edgeBounds[j]) {

                        newEdge = true;

                    }

                }

                ti.edgeBounds = edgeBounds;

                // create new shared buffers for new LOD and reuse exisiting buffer for old LOD
                if (ti.prevLod != ti.lod) {

                    newTerrain.push([
                      ti.lods[0],
                      ti.lods[0],
                      ti.lods[ti.lod],
                      ti.lods[ti.lod],
                      edgeBounds,
                      c[i].userData?.terrainInfo?.coords,
                      toRaw(c[i].userData?.terrainInfo?.uvShift),
                      toRaw(c[i].userData?.terrainInfo?.height),
                      [],
                      [],
                      scale.value
                    ]);

                }

                if ((ti.prevLod === ti.lod) && (newEdge == true)) {

                    updateTerrain.push([
                      ti.lods[0],
                      ti.lods[0],
                      ti.lods[ti.lod],
                      ti.lods[ti.lod],
                      edgeBounds,
                      c[i].userData?.terrainInfo?.coords,
                      toRaw(c[i].userData?.terrainInfo?.uvShift),
                      toRaw(c[i].userData?.terrainInfo?.height),
                      [],
                      [],
                      scale.value
                    ]);

                }

                // code below can be used instead if you want to only push updates rather than recreating shared buffer for new LOD
                // there are tradeoffs here between maximum memory allocation about 30% more for maybe faster updates ?
                // if you use this you need to make sure the worker thread preallocated memory , check for releated comment in worker

/*
                    if (buffers[c[i].userData?.terrainInfo?.coords]) {

                      updateTerrain.push([ti.lods[0], ti.lods[0], ti.lods[ti.lod], ti.lods[ti.lod], edgeBounds, c[i].userData?.terrainInfo?.coords, toRaw(c[i].userData?.terrainInfo?.uvShift),toRaw(c[i].userData?.terrainInfo?.height), scale.value]);

                    } else {

                      newTerrain.push([ti.lods[0], ti.lods[0], ti.lods[ti.lod], ti.lods[ti.lod], edgeBounds, c[i].userData?.terrainInfo?.coords, toRaw(c[i].userData?.terrainInfo?.uvShift),toRaw(c[i].userData?.terrainInfo?.height), scale.value]);

                    }
*/

            }

            var endTime = performance.now();
            console.log(`update pass 2 took ${endTime - startTime} milliseconds`)

            trackPosition.copy(camera.value.camera.position);

            return {newTerrain, updateTerrain};

        },
        trackCamera = () => {
        
            let d = trackPosition.distanceTo(camera.value.camera.position);

            // process new updates when distance exceeded and no updates in flight
            if (d > 100 && Object.keys(geometryQueue).length === 0) {

                trackPosition.copy(camera.value.camera.position);
                let {newTerrain:newTerrain, updateTerrain:updateTerrain} = updateLOD();

                worker.postMessage({
                    action: 'buildTerrainGeometry',
                    request: newTerrain
                });

                worker.postMessage({
                    action: 'updateTerrainGeometry',
                    request: updateTerrain
                });

            }

            setTimeout(()=> {

                trackCamera();

            }, 3000);

        },
        // Creates a worker to handle heavy updates
        startWorker = () => {

            worker.onmessage = (event) => {

                var { action, response } = event.data;

                // assign the new buffer and rebuild geometry
                if (action === 'buildTerrainGeometryComplete') {

                    for (var i in terrainNode.value.children) {

                        var id = terrainNode.value.children[i]?.userData?.terrainInfo?.coords;
                        var sharedAttributes = response[id];
                        if (sharedAttributes) {

                            buffers[id] = sharedAttributes;
                            geometryQueue[id] = {
                                action: 'build',
                                node: terrainNode.value.children[i]
                            }
                            // buildGeometry(sharedAttributes, terrainNode.value.children[i]);

                        }

                    }

                }

                // update geometry (buffer allready updated in worker)
                if (action === 'updateTerrainGeometryComplete') {

                    for (var i in terrainNode.value.children) {

                        var id = terrainNode.value.children[i]?.userData?.terrainInfo?.coords;
                        if (response[id] === true && buffers[id]) {

                            geometryQueue[id] = {
                                action: 'update',
                                node: terrainNode.value.children[i]
                            }
                            // updateGeometry(buffers[id], terrainNode.value.children[i]);

                        }

                    }

                }

                if (action === 'getHeightMapComplete') {

                    if (heightMaps.value[`${response.gridX},${response.gridY}`] === undefined) {

                        heightMaps.value[`${response.gridX},${response.gridY}`] = response.heightMap;
                        decimateMaps.value[`${response.gridX},${response.gridY}`] = response.decimateMap;

                    }

                    // build when all maps loaded
                    if (Object.keys(heightMaps.value).length === terrainData.value.grids.length) {

                        buildMap();

                    }

                }

                /*
                 * Get precomputed normals in addition to heights.
                 * Memory intensive, also calculation may not be very accurate
                 * Vertex calculation probably better.
                 */
                if (action === 'getHeightNormalMapComplete') {

                    if (heightMaps.value[`${response.gridX},${response.gridY}`] === undefined) {

                        heightMaps.value[`${response.gridX},${response.gridY}`] = response.heightMap;
                        normalMaps.value[`${response.gridX},${response.gridY}`] = response.normalMap;

                    }

                    // build when all maps loaded
                    if (Object.keys(heightMaps.value).length === terrainData.value.grids.length) {

                        buildMap();

                    }

                }

            }

        },
        // build geometry 
        buildGeometry = async (attr, node, callback) => {

            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(attr.position, 3));
            geometry.setAttribute('normal', new THREE.Float32BufferAttribute(attr.normal, 3));
            geometry.setAttribute('tangent', new THREE.Float32BufferAttribute(attr.tangent, 3));
            geometry.setAttribute('bitangent', new THREE.Float32BufferAttribute(attr.bitangent, 3));
            geometry.setAttribute('uv', new THREE.Float32BufferAttribute(attr.uv, 2));
            geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(attr.uv2, 2));
            geometry.setIndex(new THREE.BufferAttribute(attr.indices, 1));
            // geometry.setIndex(attr.indices); // breaks , i think required basic array
            // geometry.computeVertexNormals(); // slow af , instead we do normal calc manually in worker
            geometry.attributes.position.needsUpdate = true;
            geometry.attributes.uv.needsUpdate = true;
            geometry.attributes.uv2.needsUpdate = true;
            geometry.attributes.normal.needsUpdate = true;
            geometry.attributes.tangent.needsUpdate = true;
            geometry.attributes.bitangent.needsUpdate = true;

            // if we use animation frame it seems to be fast but actually only one update per second..
            requestAnimationFrame(() => {
            });
            swapGeometry(node, toRaw(geometry))
            callback();

        },
        // update geometry in place
        updateGeometry = async (attr, node, callback) => {

            node.geometry.attributes.position.array.set(attr.position, 0);
            node.geometry.attributes.normal.array.set(attr.normal, 0);
            node.geometry.attributes.tangent.array.set(attr.tangent, 0);
            node.geometry.attributes.bitangent.array.set(attr.bitangent, 0);
            node.geometry.attributes.uv.array.set(attr.uv, 0);
            node.geometry.attributes.uv2.array.set(attr.uv2, 0);
            node.geometry.index.array.set(attr.indices, 0);
            node.geometry.attributes.position.needsUpdate = true;
            node.geometry.attributes.normal.needsUpdate = true;
            node.geometry.attributes.tangent.needsUpdate = true;
            node.geometry.attributes.bitangent.needsUpdate = true;
            node.geometry.index.needsUpdate = true;
            // node.geometry.computeVertexNormals(); // slow af

            callback();

        },
        swapGeometry = async (node, geometry) => {

            // clean up old geometry
            node.geometry.dispose();

            // Assign
            node.geometry = geometry;

            // Set Update TODO maybe push to worker also
            // node.geometry.attributes.position.needsUpdate = true;
            node.material.needsUpdate = true;

        },
        /*
         * coordinate updates in the main thread to minimize visual sheering
         * and manage render pipeline
         */
        processGeometryQueue = async () => {

            if (Object.keys(geometryQueue).length > 0 && inUpdate.value === false) {
    
                inUpdate.value = true;

                await mergeEdges();

                for (var i in geometryQueue) {

                    if (geometryQueue[i].action === 'update') {

                        updateGeometry(buffers[i], geometryQueue[i].node, function() {

                            delete geometryQueue[i];

                        })

                    } else if  (geometryQueue[i].action === 'build') {

                        buildGeometry(buffers[i], geometryQueue[i].node, function() {

                            delete geometryQueue[i];

                        });

                    }

                }

            } else {

                inUpdate.value = false;
                
                // final step is to fix the normal edges and update(notify) renderer

            }

            setTimeout(() => {

                processGeometryQueue();

            }, 300)

        },
        /*
         * This function runs at the end of the update pipeline, we loop over the whole map
         * and fix(average) the normals between neighbors. (makes it look seamless).
         */
        mergeEdges = () => {

            // run over all children, if the node below or to the right of current is in the queue
            // then we average normals between their edges in place in the buffer.
            for (let i=0; i < terrainNode.value.children.length; i++) {

                let terrainInfo = terrainNode.value.children[i].userData?.terrainInfo;
                let east = terrainInfo.neighbors.east?.userData?.terrainInfo;
                let south = terrainInfo.neighbors.south?.userData?.terrainInfo;

                if (buffers[terrainInfo.coords]) {

                    if (buffers[east?.coords]) {

                        var mergeN, mergeT, mergeLod;

                        // low lod int are higher fidelity
                        if (terrainInfo.lod > east.lod) {

                            mergeN = 'half';
                            mergeT = 'full';

                        } else if (terrainInfo.lod < east.lod) {

                            mergeN = 'full';
                            mergeT = 'half';

                        } else {

                            mergeN = 'full';
                            mergeT = 'full';

                        }

                        // load edge vertices for fast picking 
                        var Tpairs = mergePairs[terrainInfo.lod];
                        var Npairs = mergePairs[east.lod];

                        for (var j=0; j < Tpairs.horizontal.current[mergeT].length; j+=3) {

                            let Navg = normalize([
                                (buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]]
                                + buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]])/2,
                                (buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]]
                                + buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]])/2,
                                (buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]]
                                + buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]])/2
                            ]);

                            // this node
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]] = Navg[0];
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]] = Navg[1];
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]] = Navg[2];

                            // east node
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]] = Navg[0];
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]] = Navg[1];
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]] = Navg[2];

/*
                            // position , only necessary if smooting or other filters applied
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j]] 
                              = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j]];
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+1]] 
                              = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j+1]];
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+2]] 
                              = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j+2]];
*/

/*
                            let c = [
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]],
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]],
                                buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]],
                            ]

                            let n = [
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]],
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]],
                                buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]],
                            ]

                            const m1 = magnitude(c);
                            const m2 = magnitude(n);

                            const w1 = m1 / (m1+m2);
                            const w2 = m2 / (m1+m2);

                            const avg = normalize([
                                c[0] * w1 + n[0] * w2,
                                c[1] * w1 + n[1] * w2,
                                c[2] * w1 + n[2] * w2
                            ]);

                            // this node
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j]] = avg[0];
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+1]] = avg[1];
                            buffers[terrainInfo.coords].normal[Tpairs.horizontal.current[mergeT][j+2]] = avg[2];

                            // east node
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j]] = avg[0];
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+1]] = avg[1];
                            buffers[east.coords].normal[Npairs.horizontal.neighbor[mergeN][j+2]] = avg[2];
*/
                        }

                    }

                }

            }

            for (let i=0; i < terrainNode.value.children.length; i++) {

                let terrainInfo = terrainNode.value.children[i].userData?.terrainInfo;
                let east = terrainInfo.neighbors.east?.userData?.terrainInfo;
                let south = terrainInfo.neighbors.south?.userData?.terrainInfo;

                if (buffers[terrainInfo.coords]) {

                    if (buffers[south?.coords]) {

                        // low lod int are higher fidelity
                        if (terrainInfo.lod > south.lod) {

                            mergeN = 'half';
                            mergeT = 'full';

                        } else if (terrainInfo.lod < south.lod) {

                            mergeN = 'full';
                            mergeT = 'half';

                        } else {

                            mergeN = 'full';
                            mergeT = 'full';

                        }

                        // load edge vertices for fast picking 
                        var Tpairs = mergePairs[terrainInfo.lod];
                        var Npairs = mergePairs[south.lod];

                        // stitch segments
                        for (var j=0; j < Tpairs.vertical.current[mergeT].length; j+=3) {

                            // this is unused, i tried several ways to blend, seems difficult to get without seams
                            let Navg = normalize([
                                (buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]]
                                + buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]])/2,
                                (buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]]
                                + buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]])/2,
                                (buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]]
                                + buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]])/2
                            ]);

                            // this node
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]] = Navg[0];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]] = Navg[1];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]] = Navg[2];

                            // south node
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]] = Navg[0];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]] = Navg[1];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]] = Navg[2];
/*
                            // second way, maybe a little better?
                            let c = [
                              buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]],
                              buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]],
                              buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]]
                            ]

                            let n = [
                              buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]],
                              buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]],
                              buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]]
                            ]

                            const m1 = magnitude(c); // sqrt of sum of each component squared
                            const m2 = magnitude(n); //

                            const w1 = m1 / (m1+m2);
                            const w2 = m2 / (m1+m2);

                            const avg = normalize([
                                c[0] * w2 + n[0] * w1,
                                c[1] * w2 + n[1] * w1,
                                c[2] * w2 + n[2] * w1
                            ]);

                            // this node
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]] = avg[0];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]] = avg[1];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]] = avg[2];

                            // east node
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]] = avg[0];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]] = avg[1];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]] = avg[2];
*/
/*
                            // position , only necessary if smooting or other filters applied
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j]] 
                              = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j]];
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+1]] 
                              = buffers[east.coords].position[Npairs.horizontal.neighbor[mergeN][j+1]];
                            buffers[terrainInfo.coords].position[Tpairs.horizontal.current[mergeT][j+2]] 

                            // this node

                            // south node
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]]
                              = buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]];

                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]]
                              = buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]];

                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]]
                              = buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]];

/*
                            // this node
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j]] = Navg[0];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+1]] = Navg[1];
                            buffers[terrainInfo.coords].normal[Tpairs.vertical.current[mergeT][j+2]] = Navg[2];

                            // south node
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j]] = Navg[0];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+1]] = Navg[1];
                            buffers[south.coords].normal[Npairs.vertical.neighbor[mergeN][j+2]] = Navg[2];
*/
/*

                            // position
                            if ((j+1)%3 === 0) {

                                buffers[south.coords].position[Npairs.vertical.neighbor[mergeN][j]]
                                  = buffers[terrainInfo.coords].position[Tpairs.vertical.current[mergeT][j]];
                                
                            }
*/

                        }

                    }

                 }

              }

          },
          // TODO: write geometry to disk
          normalize = ([a,b,c]) => {

              let length = Math.sqrt( a*a + b*b + c*c );

              if (length === 0) 
                return [0, 0, 0];

              return [a / length, b / length, c / length];

          },
          magnitude = ([a,b,c]) => {

              return Math.sqrt( a*a + b*b + c*c );

          },
          toggleWireframe = () => {

              terrainNode.value.traverse((n) => {

                  if (n.material) {

                      if (n.material.wireframe == true) {

                          n.material.wireframe = false;
       
                      } else {

                          n.material.wireframe = true;
       
                      }

                  }

              });

          },
          // uses fast-png (supports 16bit greyscale)
          loadHeightMap = (imagePath) => {

              return new Promise(async (resolve, reject) => {

                  try {

                      const response = await axios.get(imagePath, { responseType: 'arraybuffer' });
                      const arrayBuffer = response.data;
                      
                      const imageData = await decode(arrayBuffer);
                      const { width, height, data } = imageData;

                      console.log({ width, height, data });

                      resolve({ width, height, data });

                  } catch (error) {

                      reject(error);

                  }

              });

          },
          loadImage = (imagePath) => {

              return new Promise((resolve, reject) => {

                const image = new Image();

                image.onload = () => {

                    const width = image.width;
                    const height = image.height;

                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const context = canvas.getContext('2d');
                    context.drawImage(image, 0, 0);

                    const imageData = context.getImageData(0, 0, width, height);
                    const data = imageData.data;

                    const heights = [];

                    resolve({width, height, data});

                };

                image.onerror = function() {
            
                    console.error('Failed to load the image at ' + imagePath);
                    reject();

                };

                image.src = imagePath;

              })
          },
          // The supplied images from generate image have an extra duplicate pixel
          // on the right(east) and bottom(south) for consistent merge
          calculateMergePairs = () => {

              for (var lod in terrainData.value.lods) {

                  const pairs = { // pre-calculated lod neighbor segment vertex pairs for merging normals

                      // comparison of a node to it's neighbor to the east
                      horizontal: {
                          current: { // the current segment
                            full: [], // every vertex for lod
                            half: [] // every other vertex for lod
                          }, 
                          neighbor: { // the neighbor segment (right)
                            full: [], // every vertex for lod
                            half: [] // every other vertex for lod
                          }
                      },
                      vertical: {
                          current: { // the current segment
                            full: [], // every vertex for lod
                            half: [] // every other vertex for lod
                          }, 
                          neighbor: { // the neighbor segment (below)
                            full: [], // every vertex for lod
                            half: [] // every other vertex for lod
                          }
                      }

                  }; 

                  var verts = terrainData.value.lods[lod] + 1;

                  var f = 0;
                  // this (current) segment 
                  for (var j=verts - 1; j < verts*verts; j+=verts) {

                      pairs.horizontal.current.full.push(j*3);
                      pairs.horizontal.current.full.push(j*3+1);
                      pairs.horizontal.current.full.push(j*3+2);

                      if ((f/3) % 2 === 0) {

                          pairs.horizontal.current.half.push(j*3);
                          pairs.horizontal.current.half.push(j*3+1);
                          pairs.horizontal.current.half.push(j*3+2);

                      }

                      f+=3;

                  }

                  f = 0;
                  // neighbor (east) segment
                  for (var j=0; j < verts*verts; j+=verts) {

                      pairs.horizontal.neighbor.full.push(j*3);
                      pairs.horizontal.neighbor.full.push(j*3+1);
                      pairs.horizontal.neighbor.full.push(j*3+2);

                      if ((f/3) % 2 === 0) {

                          pairs.horizontal.neighbor.half.push(j*3);
                          pairs.horizontal.neighbor.half.push(j*3+1);
                          pairs.horizontal.neighbor.half.push(j*3+2);

                      }

                      f+=3;

                  }

                  f = 0;
                  // current
                  for (var i = verts * verts - verts; i < verts * verts; i++) {

                      pairs.vertical.current.full.push(i*3);
                      pairs.vertical.current.full.push(i*3+1);
                      pairs.vertical.current.full.push(i*3+2);

                      if ((f / 3) % 2 === 0) {

                          pairs.vertical.current.half.push(i*3);
                          pairs.vertical.current.half.push(i*3+1);
                          pairs.vertical.current.half.push(i*3+2);

                      }
                      f += 3;

                  }

                  f = 0;
                  // this one is the problem *** FIX
                  // neighbor (south) segment
                  for (var i = 0; i < verts; i++) {

                      pairs.vertical.neighbor.full.push(i*3);
                      pairs.vertical.neighbor.full.push(i*3+1);
                      pairs.vertical.neighbor.full.push(i*3+2);

                      if ((f / 3) % 2 === 0) {

                          pairs.vertical.neighbor.half.push(i*3);
                          pairs.vertical.neighbor.half.push(i*3+1);
                          pairs.vertical.neighbor.half.push(i*3+2);

                      }
                      f += 3;

                  }

/*
                  f = 0;
                  // this (current) segment 
                  for (var i = 0; i < verts; i++) {

                      pairs.vertical.current.full.push(i * 3);
                      pairs.vertical.current.full.push(i * 3 + 1);
                      pairs.vertical.current.full.push(i * 3 + 2);

                      if ((f / 3) % 2 === 0) {

                          pairs.vertical.current.half.push(i * 3);
                          pairs.vertical.current.half.push(i * 3 + 1);
                          pairs.vertical.current.half.push(i * 3 + 2);

                      }

                      f += 3;

                  }

                  f = 0;
                  // Neighbor (north) segment
                  for (var i = verts * verts - verts; i < verts * verts; i++) {

                      pairs.vertical.neighbor.full.push(i * 3);
                      pairs.vertical.neighbor.full.push(i * 3 + 1);
                      pairs.vertical.neighbor.full.push(i * 3 + 2);

                      if ((f / 3) % 2 === 0) {

                          pairs.vertical.neighbor.half.push(i * 3);
                          pairs.vertical.neighbor.half.push(i * 3 + 1);
                          pairs.vertical.neighbor.half.push(i * 3 + 2);

                      }

                      f += 3;

                  }
*/
                  mergePairs[lod] = pairs;

            }

        },
        // renderupdate
        process = () => {

            if (terrainMaterial?.value?.uniforms) {

/*
terrainMaterial.value.uniforms.shadowMatrix.value.copy(activeLights['sun'].shadow.matrix);
terrainMaterial.value.uniforms.shadowMap.value = activeLights['sun'].shadow.map.texture;
*/
                terrainMaterial.value.uniforms.sunPosition.value = activeLights['sun'].position.clone();
                camera.value.camera.getWorldDirection(terrainMaterial.value.uniforms.viewDirection.value);

            }

        },
        doSomething = () => {
    /*
     *const furGeometry = sampleVertexIndices(surfaceMesh.clone(), 100);
     *
     *const furMaterial = new THREE.ShaderMaterial({
     *
     *  uniforms: {
     *      time: { value: 1.0 },
     *      lightDirection: { value: new THREE.Vector3(1, 1, 1) },
     *      furTexture: { value: furMap.value },
     *      cameraposition: { type: 'v3', value: camera.value.camera.position }
     *  },
     *
     *  vertexShader: `
     *
     *      varying vec3 vPosition;
     *      varying vec3 vMvPosition;
     *      varying vec3 vNormal;
     *      varying vec3 bPosition;
     *      varying vec3 vColor;
     *
     *      uniform vec3 cameraposition;
     *
     *      varying vec3 entryPoint;
     *      varying vec3 exitPoint;
     *      varying vec3 depthOccupied;
     *      varying vec3 vCameraPosition;
     *      varying float cameraDistance;
     *      varying vec2 vUv;
     *
     *      void main() {
     *
     *          vNormal = -normal;
     *          vCameraPosition = vec4(vec4(trackPosition, 1.0) * modelViewMatrix).xyz;
     *
     *          vec4 worldPosition = inverse(viewMatrix) * mvPosition;
     *          vPosition = worldPosition.xyz;
     *          vMvPosition = mvPosition.xyz;
     *
     *          vec3 r = normalize(transpose(inverse(mat3(modelViewMatrix))) * normal);
     *
     *          // vec3 viewSpaceNormal = normalize(normal);
     *
     *          bool isEvenVertex = (gl_VertexID % 5 == 0);
     *
     *          if (isEvenVertex) {
     *
     *            // vec3 displacement = viewSpaceNormal * 0.05;
     *            // mvPosition.xyz += displacement;
     *            vColor = vec3(0.7, 0.05, 0.7);
     *
     *          } else {
     *
     *            vColor = vec3(0.9, 0.7, 0.9);
     *
     *          }
     *
     *          // Set the gl_Position using the projection matrix
     *          //gl_Position = projectionMatrix * vec4(mvPosition.xyz, 1.0);
     *          gl_Position = projectionMatrix * mvPosition;
     *          //gl_Position = projectionMatrix * viewMatrix * worldPosition;
     *
     *      }
     *
     *  `,
     *  fragmentShader: `
     *
     *      uniform sampler2D furTexture;
     *
     *      varying vec2 vUv;
     *      varying vec3 vPosition;
     *      varying vec3 vMvPosition;
     *      varying vec3 vNormal;
     *      varying vec3 vColor;
     *      varying vec3 depthOccupied;
     *
     *      varying vec3 entryPoint;
     *      varying vec3 exitPoint;
     *      varying float cameraDistance;
     *
     *      uniform vec3 vCameraPosition;
     *
     *      const int steps = 10;
     *      const float stepSize = 0.1;
     *      const float densityScale = 1.2;
     *
     *      float density(vec3 p) {
     *
     *        vec2 uv = vec2(p.x - vPosition.x, p.y -vPosition.y) * 0.5 + 0.5; // Adjust the mapping as needed
     *        return texture2D(furTexture, uv).r * densityScale;
     *
     *      }
     *
     *    vec4 sampleFur(vec3 samplePosition, vec3 marchDirection) {
     *
     *      float attenuation = 1.0;
     *
     *      float extinctionCoef = 0.8;
     *
     *      float lightIntensity = 1.0;
     *
     *      vec3 lightColor = vec3(1.0,1.0,1.0);
     *
     *      vec3 color;
     *
     *      for (int i = 0; i < steps; i++) {
     *
     *        vec3 marchPosition = samplePosition + marchDirection * stepSize * float(i);
     *        // vec3 marchPosition = samplePosition * stepSize * float(i);
     *
     *        float u = density(marchPosition);
     *      vec3 color = mix(vColor, vec3(0.0), edgeIntensity);
     *
     *      gl_FragColor = vec4(vNormal, 1.0);
     *
     *    }
     *`,
     *side: THREE.DoubleSide,
     *wireframe: true,
     * //transparent: true
     *});
     *
     *const furMesh = new THREE.SkinnedMesh(furGeometry, furMaterial);
     *
     *furMesh.bind(surfaceMesh.skeleton, surfaceMesh.bindMatrix);
     *
     *surfaceMesh.parent.add(furMesh);
     */
        },
        animate = () => {

        };

        onMounted(() => {

            loadTerrain();
            startWorker();
            processGeometryQueue();

            for (let i=0; i < terrainNode.value.children.length; i++) {

                const coords = `${currentGrid.value[0]  },${  currentGrid.value[1]}`;
                if (coords = terrainNode.value.children[i]?.userData?.terrainInfo) {

                    camera.value.camera.position.copy(terrainNode.value.children[i].position);

                }

            }
            trackPosition.copy(camera.value.camera.position);

            trackCamera();

            watch(
                () => keyPress.value,

                (first, second) => {

                    if (keyPress.value.o == true) {

                        toggleWireframe();

                    }

                },
            { deep: true });

        });

        onUnmounted(() => {
            worker.terminate();
            
            if (rootNode.value) {
                // rootNode.value.removeChild(renderer.domElement);
            }
        });

        return {
            scene,
            axios,
            rootNode,
            camera,
            trackPosition,
            trackCamera,
            updateDelta,
            debounceKey,
            loadTerrain,
            loadMaps,
            buildMap,
            updateLOD,
            terrainNode,
            cacheMap,
            mapSize,
            terrainData,
            doSomething,
            keyPress,
            toggleWireframe,
            animate,
            worker,
            startWorker,
            buildGeometry,
            updateGeometry,
            processGeometryQueue,
            loadImage,
            swapGeometry,
            getTerrainInfo,
            getTerrainMaterial,
            terrainMaterial,
            process,
            mergeEdges,
            mergePairs,
            calculateMergePairs,
            currentGrid,
            textureLoader,
            normalize,
            magnitude,
            geometryQueue,
            heightMaps,
            normalMaps,
            decimateMaps,
            textureMaps,
            loadTextures,
            loader,
            scale,
            activeLights,
            buffers,
            inUpdate
        };

    }

};

</script>

<style scoped>
div {
width: 100%;
height: 100%;
}
</style>
